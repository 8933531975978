import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "label"];

  connect() {
    this.initialLabel = this.labelTarget.innerText;
  }

  handleSelectChange() {
    const index = this.selectTarget.selectedIndex;
    const text = this.selectTarget.options[index].text;
    const value = this.selectTarget.options[index].value;
    if (value === "") {
      this.labelTarget.innerText = this.initialLabel;
    } else {
      this.labelTarget.innerText = text;
    }
  }

  handleSelectFocus() {
    if (this.selectTarget.classList.contains("focus-visible")) {
      this.element.classList.add("focus-visible");
    }
  }

  handleSelectBlur() {
    this.element.classList.remove("focus-visible");
  }
}
