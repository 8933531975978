import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  static targets = ["loginMount", "signupMount", "loginButton", "signupButton"];

  connect() {
    this.submitting = false;
  }

  handleLoginButtonClick(event) {
    event.preventDefault();
    if (this.submitting) return;
    this.loadLogin();
  }

  handleSignupButtonClick(event) {
    event.preventDefault();
    if (this.submitting) return;
    this.loadSignup();
  }

  async loadLogin() {
    this.loginButtonTarget.classList.add("active");
    this.signupButtonTarget.classList.remove("active");
    this.signupMountTarget.classList.remove("active");
    this.submitting = true;
    const resp = await fetch("/accounts/login/", {
      headers: HEADERS,
    });
    const html = await resp.text();
    this.loginMountTarget.classList.add("active");
    this.loginMountTarget.innerHTML = html;
    this.submitting = false;
  }

  async loadSignup() {
    this.signupButtonTarget.classList.add("active");
    this.loginButtonTarget.classList.remove("active");
    this.loginMountTarget.classList.remove("active");
    this.submitting = true;
    const resp = await fetch("/accounts/signup/", {
      headers: HEADERS,
    });
    const html = await resp.text();
    this.signupMountTarget.classList.add("active");
    this.signupMountTarget.innerHTML = html;
    this.submitting = false;
  }
}
