import { Controller } from "@hotwired/stimulus";
import { triggerFocus } from "./utils";

export default class extends Controller {
  static targets = ["button"];

  handleCategoryButtonClick(event) {
    this.deactivate();
    window.dispatchEvent(
      new CustomEvent("CategoryOverlaySelection", {
        detail: event.currentTarget.dataset.index,
      })
    );
  }

  handleOpenCategoryOverlayButtonClick() {
    this.activate();
  }

  handleCloseButtonClick() {
    this.deactivate();
  }

  activate() {
    this.element.classList.add("active");
    this.element.removeAttribute("aria-hidden");
    this.buttonTargets.forEach((button) => {
      button.removeAttribute("tabindex");
    });
    const button = this.buttonTargets[0];
    triggerFocus(button);
  }

  deactivate() {
    this.element.classList.remove("active");
    this.element.setAttribute("aria-hidden", "true");
    this.buttonTargets.forEach((button) => {
      button.setAttribute("tabindex", "-1");
    });
  }
}
