import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  static targets = ["continueShopping"];

  connect() {
    this.submitting = false;
  }

  getUrl() {
    const orderId = this.element.getAttribute("data-order-id");
    const cartUrlBase = this.element.getAttribute("data-cart-url-base");
    return `${cartUrlBase}${orderId}/`;
  }

  async submit(type, quantity, sessionId, orderLineId) {
    this.submitting = true;

    const formData = new FormData();
    formData.append("order_line_id", orderLineId);
    formData.append("session_id", sessionId);

    if (type === "quantity") {
      formData.append("quantity", quantity);
    } else {
      formData.append("quantity", 0);
      formData.append("upsell_quantity", quantity);
    }

    try {
      const response = await fetch(this.getUrl(), {
        method: "POST",
        headers: HEADERS,
        body: formData,
      });
      const text = await response.text();
      this.updateContent(text);
    } catch (error) {
      this.element.innerHTML = error;
    } finally {
      this.submitting = false;
    }
  }

  handleSpinnerClick(event) {
    event.preventDefault();
    if (this.submitting) return;
    const typeEl = event.currentTarget.closest("[data-type]");
    const detailsEl = event.currentTarget.closest("[data-session-id]");
    const direction = event.currentTarget.getAttribute("data-direction");
    const type = typeEl.getAttribute("data-type");
    const sessionId = detailsEl.getAttribute("data-session-id");
    const orderLineId = detailsEl.getAttribute("data-order-line-id");
    const quantity = direction === "increase" ? 1 : -1;
    this.submit(type, quantity, sessionId, orderLineId);
  }

  handleRemoveClick(event) {
    event.preventDefault();
    if (this.submitting) return;
    const detailsEl = event.currentTarget.closest("[data-session-id]");
    const sessionId = detailsEl.getAttribute("data-session-id");
    const orderLineId = detailsEl.getAttribute("data-order-line-id");
    const quantity = detailsEl.getAttribute("data-quantity");
    this.submit("quantity", -quantity, sessionId, orderLineId);
  }

  async handleAddToBasket(event) {
    this.updateContent(event.detail);
  }

  updateContent(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const basket = doc.querySelector(".Basket");
    const orderId = basket.getAttribute("data-order-id");
    const quantity = basket.getAttribute("data-order-quantity");
    this.element.innerHTML = basket.innerHTML;
    this.element.setAttribute("data-order-id", orderId);
    this.element.setAttribute("data-order-quantity", quantity);
    window.dispatchEvent(new CustomEvent("BasketUpdate", { detail: quantity }));
  }
}
