import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.sessionId = this.element.getAttribute("data-session-id");
  }

  handleOpenModalButtonClick() {
    window.dispatchEvent(
      new CustomEvent("ResaleModalButtonClicked", { detail: this.sessionId })
    );
  }
}
