import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";

export default class extends Controller {
  static targets = ["swiper"];

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      speed: 400,
      slidesPerView: "auto",
    });
  }

  disconnect() {
    this.swiper.destroy();
  }

  handlePrevButtonClick() {
    this.swiper.slidePrev();
  }

  handleNextButtonClick() {
    this.swiper.slideNext();
  }
}
