import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";

export default class extends Controller {
  static targets = ["swiper", "slide"];

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const initialCategory = urlParams.get("category");
    const initialSlide = initialCategory
      ? this.slideTargets.findIndex((slide) => {
          return slide.getAttribute("data-slug") === initialCategory;
        })
      : 0;

    const slugs = this.slideTargets.map((slide) => {
      return slide.getAttribute("data-slug");
    });

    this.swiper = new Swiper(this.swiperTarget, {
      speed: 400,
      slidesPerView: "auto",
      centeredSlides: true,
      initialSlide,
      on: {
        slideChange(event) {
          const slug = slugs[event.activeIndex];
          window.dispatchEvent(
            new CustomEvent("CategoryChanged", {
              detail: slug,
            })
          );
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
  }

  handleSlideClick(event) {
    // This is not defined using the 'on' swiper option as that doesn't fire on the first click
    // after the user interacts with a select element on Safari minimal reproduction:
    // https://codepen.io/heyscam/pen/ExddPdo
    const index = event.currentTarget.getAttribute("data-index");
    this.swiper.slideTo(index);
  }

  handleCategoryOverlaySelection(event) {
    this.swiper.slideTo(event.detail);
  }

  handlePrevButtonClick() {
    this.swiper.slidePrev();
  }

  handleNextButtonClick() {
    this.swiper.slideNext();
  }

  handleOpenOverlayButtonClick() {
    window.dispatchEvent(new Event("OpenCategoryOverlayButtonClick"));
  }
}
