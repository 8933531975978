import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  connect() {
    this.submitting = false;
  }

  async update() {
    this.submitting = true;

    try {
      const formData = new FormData(this.element);
      formData.delete("csrfmiddlewaretoken");
      const query = new URLSearchParams(formData).toString();

      const response = await fetch(
        `${this.element.getAttribute("action")}/?${query}`,
        { headers: HEADERS }
      );
      if (response.status !== 200) {
        throw new Error(
          "Sorry, we could not submit this form. Please check you have cookies enabled, then refresh this page and try again."
        );
      }
      const text = await response.text();
      this.element.innerHTML = text;
    } catch (error) {
      this.element.innerText = error;
      Sentry.captureMessage("Booking widget handleFormChange error");
    } finally {
      this.submitting = false;
    }
  }

  async submit() {
    this.submitting = true;

    try {
      const response = await fetch(this.element.getAttribute("action"), {
        method: "POST",
        headers: HEADERS,
        body: new FormData(this.element),
      });
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }
      if (response.status >= 400) {
        throw new Error(
          "Sorry, we could not submit this form. Please check you have cookies enabled, then refresh this page and try again."
        );
      } else {
        const text = await response.text();
        if (response.status === 201) {
          window.dispatchEvent(
            new CustomEvent("AddToBasket", { detail: text })
          );
        } else {
          this.element.innerHTML = text;
        }
      }
    } catch (error) {
      this.element.innerText = error;
      Sentry.captureMessage("Form error");
    } finally {
      this.submitting = false;
    }
  }

  handleFormChange(event) {
    event.preventDefault();
    if (this.submitting) return;
    this.update();
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.submitting) return;
    this.submit();
  }
}
