import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["media", "overlay", "item"];

  connect() {
    this.index = null;
  }

  handleItemClick(event) {
    this.overlayTarget.classList.add("active");
    this.index = this.itemTargets.indexOf(event.currentTarget);
    this.activateItem(this.index);
  }

  activateItem(index) {
    const item = this.itemTargets[index];
    if (item.getAttribute("data-embed")) {
      this.mediaTarget.innerHTML = item.getAttribute("data-embed");
    } else {
      const img = document.createElement("img");
      img.src = item.getAttribute("data-src");
      img.alt = "";
      this.mediaTarget.innerHTML = img.outerHTML;
    }
  }

  handleCloseButtonClick() {
    this.overlayTarget.classList.remove("active");
  }

  handlePrevButtonClick() {
    this.index--;
    if (this.index < 0) {
      this.index = this.itemTargets.length - 1;
    }
    this.activateItem(this.index);
  }
  handleNextButtonClick() {
    this.index++;
    if (this.index >= this.itemTargets.length) {
      this.index = 0;
    }
    this.activateItem(this.index);
  }
}
