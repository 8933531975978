import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  connect() {
    this.offset = 0;
  }

  handleClick() {
    const urlParams = new URLSearchParams(window.location.search);

    this.offset += window.bridge.PAGE_SIZE;
    urlParams.set("offset", this.offset);
    urlParams.set("count", window.bridge.PAGE_SIZE);

    const url = `${window.location.origin}${
      window.location.pathname
    }?${urlParams.toString()}`;

    fetch(url, {
      headers: HEADERS,
    })
      .then((response) => response.text())
      .then((text) => {
        window.dispatchEvent(new CustomEvent("MoreLoaded", { detail: text }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
