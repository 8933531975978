import { Controller } from "@hotwired/stimulus";

const DURATION = 800;

export default class extends Controller {
  static targets = ["image"];

  connect() {
    this.playing = false;
    this.activeIndex = 0;
    this.imgElCounter = 0;

    this.handleInterval = this.handleInterval.bind(this);
    if (this.imageTargets) {
      this.imageTargets.forEach((image) => {
        image.addEventListener("load", this.handleLoaded);
        if (image.complete && image.naturalHeight !== 0)
          this.handleLoaded(image);
      });
    }
  }

  destroy() {
    this.stopCarousel();
  }

  startCarousel(duration) {
    this.interval = setInterval(this.handleInterval, duration);
    this.playing = true;
  }

  stopCarousel() {
    clearInterval(this.interval);
    this.playing = false;
  }

  handleInterval() {
    this.imageTargets[this.activeIndex].classList.remove("active");
    this.activeIndex++;
    if (this.activeIndex > this.imageTargets.length - 1) {
      this.activeIndex = 0;
    }
    this.imageTargets[this.activeIndex].classList.add("active");
  }

  handleLoaded() {
    if (this.imageTargets.length === 1) return;
    this.imgElCounter++;
    if (this.imgElCounter === this.imageTargets.length) {
      this.startCarousel(DURATION);
    }
  }

  handleControlButtonClick(e) {
    e.preventDefault();
    if (this.playing) {
      this.stopCarousel();
    } else {
      this.startCarousel(DURATION);
    }
  }
}
