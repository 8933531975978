import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  static targets = ["content", "background"];

  getModalContent(sessionId) {
    const url = `/on-site/set-resale-qty/${sessionId}`;
    window
      .fetch(url, {
        headers: HEADERS,
      })
      .then((response) => response.text())
      .then((text) => {
        this.contentTarget.innerHTML = text;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  openModal(sessionId) {
    this.element.classList.add("active");
    this.getModalContent(sessionId);
  }

  closeModal() {
    this.element.classList.remove("active");
  }

  handleClick(event) {
    this.openModal(event.detail);
  }

  handleBackgroundElClicked() {
    this.closeModal();
  }
}
