import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  connect() {
    this.orderLineId = this.element.getAttribute("data-order-line-id");
    this.checkInQuantity = this.element.getAttribute("data-quantity");
    this.url = this.element.getAttribute("data-url");
  }

  async checkIn() {
    const formData = new FormData();
    formData.append("order_line_id", this.orderLineId);
    formData.append("quantity", this.checkInQuantity);
    formData.append("action", "check_in");
    try {
      const response = await fetch(`${this.url}`, {
        method: "POST",
        headers: HEADERS,
        body: formData,
      });
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleCheckInButtonClick() {
    this.checkIn();
  }

  handleOpenModalButtonClick() {
    window.dispatchEvent(
      new CustomEvent("CheckInModalButtonClicked", { detail: this.orderLineId })
    );
  }
}
