import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";

export default class extends Controller {
  connect() {
    this.interval = setInterval(() => {
      this.checkOrderStatus();
    }, 5000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  checkOrderStatus() {
    const orderId = this.element.getAttribute("data-order-id");

    fetch(`/on-site/sales/order-status/${orderId}/`, {
      headers: HEADERS,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Paid") {
          window.location.href = "/on-site/sales/checkout-complete/";
        }
      });
  }
}
