export function mapValues(value, low1, high1, low2, high2, clamp = false) {
  var val = low2 + ((value - low1) * (high2 - low2)) / (high1 - low1);
  if (clamp) {
    if (high2 > low2) {
      if (val > high2) {
        val = high2;
      }
      if (val < low2) {
        val = low2;
      }
    } else {
      if (val < high2) {
        val = high2;
      }
      if (val > low2) {
        val = low2;
      }
    }
  }
  return val;
}

export function easeOut(t, b, c, d) {
  t /= d;
  return -c * t * (t - 2) + b;
}

export class CycleArray extends Array {
  constructor() {
    super(...arguments);
    this.index = 0;
  }
  first() {
    return this[0];
  }
  next() {
    if (this.length === 0) return;
    this.index++;

    if (this.index === this.length) this.index = 0;
    return this[this.index];
  }
  prev() {
    if (this.length === 0) return;
    this.index--;
    if (this.index === -1) this.index = this.length - 1;
    return this[this.index];
  }
}

export function addCurrentQueryToURL(href) {
  const search = window.location.search;
  const url = search ? `${href}${search}` : href;
  return url;
}

export function getUrlAndTitleFromLink(element) {
  const url = addCurrentQueryToURL(element.getAttribute("href"));
  const pageTitle = element.getAttribute("data-page-title");
  return {
    url,
    pageTitle,
  };
}

export const HEADERS = {
  "X-CSRFToken": window.bridge.CSRF_TOKEN,
  "X-Requested-With": "XMLHttpRequest",
};

export function sleep(delay) {
  const promise = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
  return promise;
}

export function indexLoop(index, arrayLength, isGoingBackwards) {
  if (isGoingBackwards) {
    index--;
    if (index < 0) {
      index = arrayLength - 1;
    }
  } else {
    index++;
    if (index > arrayLength - 1) {
      index = 0;
    }
  }
  return index;
}

export function triggerFocus(element) {
  // https://stackoverflow.com/questions/30376628/how-to-trigger-focus-event-on-a-textbox-using-javascript
  const eventType = "onfocusin" in element ? "focusin" : "focus";
  const bubbles = "onfocusin" in element;
  const event = new Event(eventType, { bubbles: bubbles, cancelable: true });
  element.focus();
  element.dispatchEvent(event);
}
