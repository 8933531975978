import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";
import * as Sentry from "@sentry/browser";

export default class Payment extends Controller {
  static targets = ["email", "firstName", "lastName", "submitButton", "errors"];

  connect() {
    const appearance = {
      variables: {
        colorPrimary: "#528deb",
        colorText: "#30313d",
        colorDanger: "#d70000",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "4px",
        borderRadius: "0.25rem",
      },
      rules: {
        ".Input": {
          borderColor: "#c7cacb",
          boxShadow: "none",
        },
        ".Input:focus": {
          outline: "0.25rem solid #528deb",
        },
      },
    };

    this.stripe = window.Stripe(window.bridge.STRIPE_PUBLISHABLE_KEY);
    this.elements = this.stripe.elements({
      clientSecret: window.bridge.CLIENT_SECRET,
      appearance,
    });
    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount("#Payment-element");
    this.submitting = false;
  }

  destroy() {
    this.paymentElement.unmount();
  }

  setErrorMessage(message) {
    this.errorsTarget.textContent = message;
  }

  async handleFormSubmission(event) {
    event.preventDefault();
    if (this.submitting) return;
    this.submitting = true;
    this.submitButtonTarget.classList.add("loading");
    this.setErrorMessage("");
    let initializationSuccessful;
    try {
      console.log("Confirming order");
      initializationSuccessful = await this.confirmOrder();
      console.log("Order confirmed");
    } catch (error) {
      console.log("Order confirmation error");
      this.setErrorMessage(error.message);
    }
    if (initializationSuccessful) {
      try {
        console.log("Confirming payment");
        await this.confirmPayment();
        console.log("Payment confirmed");
      } catch (error) {
        console.log("Payment confirmation error");
        this.setErrorMessage(error.message);
      }
    }
    console.log("Payment complete");
    setTimeout(() => {
      this.submitting = false;
      this.submitButtonTarget.classList.remove("loading");
    }, 200);
  }

  async confirmOrder() {
    let response;

    const formData = new FormData();
    formData.append("email", this.emailTarget.value);
    formData.append("firstName", this.firstNameTarget.value);
    formData.append("lastName", this.lastNameTarget.value);
    formData.append("orderId", this.element.getAttribute("data-order-id"));

    response = await fetch(window.bridge.CONFIRM_ORDER_URL, {
      method: "POST",
      headers: HEADERS,
      body: formData,
    });
    if (response.status !== 200) {
      Sentry.captureMessage("Payment error 01");
      throw new Error(
        "Sorry! An error occured - the tech team have been alerted. Issue code 01."
      );
    }
    let data;
    try {
      data = await response.json();
      if (data.success === undefined) {
        Sentry.captureMessage("Payment error 02");
        throw new Error(
          "Sorry! An error occured - the tech team have been alerted. Issue code 02."
        );
      }
    } catch {
      Sentry.captureMessage("Payment error 03");
      throw new Error(
        "Sorry! An error occured - the tech team have been alerted. Issue code 03."
      );
    }
    if (data.success) {
      return true;
    } else {
      if (data.message) {
        throw new Error(data.message);
      } else {
        Sentry.captureMessage("Payment error 04");
        throw new Error("Unknown error");
      }
    }
  }

  async confirmPayment() {
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: window.bridge.CHECKOUT_COMPLETE_URL,
      },
    });

    if (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  }
}
