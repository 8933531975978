import { CycleArray } from "./utils";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    const dataEl = this.element.querySelector("#LineupPromo-data");

    this.slotData = [
      [16, 38, 6],
      [30, 30, 14],
      [58, 36, 7],
      [77, 25, 11],
      [20, 60, 8],
      [41, 56, 6],
      [65, 60, 16],
      [87, 80, 7],
      [8, 85, 5],
      [40, 83, 10],
    ];

    this.imageData = new CycleArray(...JSON.parse(dataEl.textContent));

    this.slotData.forEach((slotDataItem, index) => {
      const [slotX, slotY, slotScale] = slotDataItem;
      const url = this.imageData.next();
      new Image(index, url, slotX, slotY, slotScale, this);
    });
  }

  handleImageComplete(index) {
    const url = this.imageData.next();
    const slotDataItem = this.slotData[index];
    const [slotX, slotY, slotScale] = slotDataItem;
    new Image(index, url, slotX, slotY, slotScale, this);
  }

  handlePauseButtonClick() {
    this.imageTargets.forEach((activeImgEl) => {
      activeImgEl.classList.toggle("paused");
    });
  }
}

class Image {
  constructor(index, url, slotX, slotY, slotScale, controller) {
    // Vars
    this.index = index;
    this.url = url;
    this.slotX = slotX;
    this.slotY = slotY;
    this.slotScale = slotScale;
    this.controller = controller;
    this.el = this.createEl();
    this.layout();

    // Binds
    this.handleLoaded = this.handleLoaded.bind(this);

    // Listeners
    this.el.addEventListener("load", this.handleLoaded);
    if (this.el.complete) this.handleLoaded();
    this.el.addEventListener(
      "animationend",
      this.handleAnimationEnd.bind(this)
    );
  }

  createEl() {
    const el = document.createElement("img");
    el.src = this.url;
    el.alt = "";
    el.className = "LineupPromo-image";
    el.setAttribute("data-lineupPromo-target", "image");
    this.controller.element.appendChild(el);
    return el;
  }

  handleLoaded() {
    this.el.classList.add("animated");
  }

  layout() {
    const width = 360;
    const height = 360;

    this.el.style.width = `${width}px`;
    this.el.style.height = `${height}px`;
    this.el.style.top = `${height * -0.5}px`;
    this.el.style.left = `${width * -0.5}px`;

    const x = `${(this.controller.element.offsetWidth * this.slotX) / 100}px`;
    const y = `${(this.controller.element.offsetHeight * this.slotY) / 100}px`;
    const delay = Math.random() * 3;

    this.el.style.setProperty("--delay", `${delay}s`);
    this.el.style.setProperty("--x", x);
    this.el.style.setProperty("--y", y);
    this.el.style.setProperty("--scale", this.slotScale / 20);
  }

  handleAnimationEnd() {
    this.controller.handleImageComplete(this.index);
    this.destroy();
  }

  destroy() {
    this.el.remove();
  }
}
