import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["video"];

  connect() {
    useIntersection(this);
    this.playing = false;
  }

  appear() {
    if (this.playing) return;
    this.playing = true;
    this.play();
  }

  async play() {
    try {
      await this.videoTarget.play();
    } catch (err) {
      // Low battery iPhone etc.
    }
  }

  pause() {
    this.videoTarget.pause();
  }

  handleControlButtonClick(e) {
    e.preventDefault();
    this.playing = !this.playing;
    if (this.playing) {
      this.play();
    } else {
      this.pause();
    }
  }
}
