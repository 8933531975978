import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  handleClick(e) {
    e.preventDefault();
    if (this.inputTarget.value == "Yes") {
      this.inputTarget.value = "No";
      this.element.classList.remove("active");
    } else {
      this.inputTarget.value = "Yes";
      this.element.classList.add("active");
    }
  }
}
