import { Controller } from "@hotwired/stimulus";
import { HEADERS, getUrlAndTitleFromLink } from "./utils";

export default class extends Controller {
  handleLinkClick(event) {
    event.preventDefault();
    const { url, pageTitle } = getUrlAndTitleFromLink(event.currentTarget);
    this.loadContent(url, pageTitle);
  }

  handleListViewLinkClick(event) {
    const { url, pageTitle } = event.detail;
    this.loadContent(url, pageTitle);
  }

  loadContent(url, pageTitle) {
    window.dispatchEvent(
      new CustomEvent("NavigateTo", { detail: { url, pageTitle } })
    );
    this.element.classList.add("active");
    this.element.classList.add("loading");

    window
      .fetch(url, {
        headers: HEADERS,
      })
      .then((response) => response.text())
      .then((text) => {
        this.element.innerHTML = text;
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        this.element.classList.remove("loading");
      });
  }

  handleCloseLinkClick(event) {
    event.preventDefault();
    const { url, pageTitle } = getUrlAndTitleFromLink(event.currentTarget);
    window.dispatchEvent(
      new CustomEvent("NavigateTo", { detail: { url, pageTitle } })
    );
    this.close();
  }

  close() {
    this.element.innerHTML = "";
    this.element.classList.remove("active");
  }
}
