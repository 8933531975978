import { Controller } from "@hotwired/stimulus";
import { useMatchMedia } from "stimulus-use";

const UP = "UP";
const DOWN = "DOWN";
const STATIC = "STATIC";

export default class extends Controller {
  static targets = [
    "burger",
    "subNavGroup",
    "mobileMenu",
    "mobileNav",
    "mobileSubNavGroup",
    "subNav",
    "subNavOverlay",
    "mobileSubNavBack",
    "mobileSubNavBackContent",
    "monogramButton",
    "authOverlay",
    "basketOverlay",
    "nav",
    "navTicker",
    "authLoginButton",
    "basketQuantity",
  ];

  connect() {
    this.activeSubNavId = null;
    this.activeMobileSubNavId = null;
    this.mobileMenuActive = false;
    this.basketOverlayActive = false;
    useMatchMedia(this, {
      mediaQueries: {
        mobile: "(max-width: 1279px)",
      },
    });
    this.handleScrollInterval = this.handleScrollInterval.bind(this);
    this.interval = setInterval(this.handleScrollInterval, 100);
  }

  notMobile() {
    if (this.mobileMenuActive) {
      this.mobileMenuActive = false;
      this.update();
    }
  }

  handleBurgerClick(event) {
    event.preventDefault();
    this.mobileMenuActive = !this.mobileMenuActive;
    if (this.mobileMenuActive) {
      this.authOverlayActive = false;
      this.basketOverlayActive = false;
      this.activeSubNavId = null;
    }
    this.update();
  }

  handleBasketButtonClick(event) {
    event.preventDefault();
    this.authOverlayActive = false;
    this.mobileMenuActive = false;
    this.activeSubNavId = null;
    //
    this.basketOverlayActive = !this.basketOverlayActive;
    this.update();
  }

  handleNavAccountButtonClick(event) {
    event.preventDefault();
    this.basketOverlayActive = false;
    this.mobileMenuActive = false;
    this.activeSubNavId = null;
    //
    this.authOverlayActive = !this.authOverlayActive;
    this.update();
  }

  handleNavItemClick(event) {
    event.preventDefault();
    this.authOverlayActive = false;
    this.basketOverlayActive = false;
    this.mobileMenuActive = false;
    const id = event.currentTarget.getAttribute("data-sub-nav-group-id");
    if (this.activeSubNavId == id) {
      this.activeSubNavId = null;
    } else {
      this.activeSubNavId = id;
    }
    this.update();
  }

  update() {
    // The overlay, burger, ticker and subnav targets are not present in on-site pages,
    // so the update method would throw an error if the checks were not in place.
    if (this.hasAuthOverlayTarget) {
      if (this.authOverlayActive) {
        this.authOverlayTarget.classList.add("active");
        this.authLoginButtonTarget.click();
      } else {
        this.authOverlayTarget.classList.remove("active");
      }
    }
    if (this.hasBasketOverlayTarget) {
      if (this.basketOverlayActive) {
        this.basketOverlayTarget.classList.add("active");
      } else {
        this.basketOverlayTarget.classList.remove("active");
      }
    }
    if (
      this.hasMobileMenuTarget &&
      this.hasBurgerTarget &&
      this.hasNavTickerTarget
    ) {
      if (this.mobileMenuActive) {
        this.mobileMenuTarget.classList.add("active");
        this.burgerTarget.classList.add("active");
        this.navTickerTarget.classList.remove("active");
      } else {
        this.mobileMenuTarget.classList.remove("active");
        this.burgerTarget.classList.remove("active");
        this.navTickerTarget.classList.add("active");
      }
    }

    if (this.hasSubNavOverlayTarget) {
      if (
        this.authOverlayActive ||
        this.basketOverlayActive ||
        this.activeSubNavId !== null
      ) {
        this.subNavOverlayTarget.classList.add("active");
      } else {
        this.subNavOverlayTarget.classList.remove("active");
      }
    }

    this.deActivateAllSubNavGroups();
    if (this.hasSubNavTarget) {
      if (this.activeSubNavId !== null) {
        if (!this.subNavTarget.classList.contains("active")) {
          this.subNavTarget.classList.add("opening");
          setTimeout(() => {
            this.subNavTarget.classList.remove("opening");
          }, 500);
        }
        this.subNavTarget.classList.add("active");
        this.activateSubNavGroup(this.activeSubNavId);
      } else {
        this.subNavTarget.classList.remove("active");
      }
    }
  }

  handleMobileNavItemClick(event) {
    event.preventDefault();
    const id = event.currentTarget.getAttribute("data-sub-nav-group-id");
    const text = event.currentTarget.innerText;
    this.mobileNavTarget.classList.remove("active");
    this.activateElementByGroupId(id, this.mobileSubNavGroupTargets);
    this.mobileSubNavBackContentTarget.innerText = text;
    this.monogramButtonTarget.classList.remove("active");
    this.mobileSubNavBackTarget.classList.add("active");
    this.activeMobileSubNavId = id;
  }

  handleMobileSubNavBackClick(event) {
    event.preventDefault();
    this.mobileNavTarget.classList.add("active");
    this.deActivateElementByGroupId(
      this.activeMobileSubNavId,
      this.mobileSubNavGroupTargets
    );
    this.monogramButtonTarget.classList.add("active");
    this.mobileSubNavBackTarget.classList.remove("active");
    this.activeMobileSubNavId = null;
  }

  handleAddToBasket() {
    // We check if there is a subNavOverlayTarget because the on-site pages do not have it.
    // If it is not present, we do not want to update the overlay.
    if (!this.basketOverlayActive && this.hasSubNavOverlayTarget) {
      this.navTarget.classList.remove("hide");
      this.authOverlayActive = false;
      this.mobileMenuActive = false;
      this.activeSubNavId = null;
      //
      this.basketOverlayActive = true;
      this.update();
    }
  }

  handleBasketUpdate(event) {
    // We check if there is a hasBasketQuantityTarget because the on-site pages do not have it
    // and we don't want to add a class to a non-existent element.
    if (this.hasBasketQuantityTarget) {
      this.basketQuantityTarget.innerText = event.detail;

      if (event.detail > 0) {
        this.basketQuantityTarget.classList.add("active");
      } else {
        this.basketQuantityTarget.classList.remove("active");
      }
    }
  }

  findElementByGroupId(id, searchElements) {
    return searchElements.find((item) => {
      return item.getAttribute("data-sub-nav-group-id") == id;
    });
  }

  activateElementByGroupId(id, searchElements) {
    const el = this.findElementByGroupId(id, searchElements);
    el.classList.add("active");
  }

  deActivateElementByGroupId(id, searchElements) {
    const el = this.findElementByGroupId(id, searchElements);
    el.classList.remove("active");
  }

  activateSubNavGroup(id) {
    this.activateElementByGroupId(id, this.subNavGroupTargets);
  }

  deActivateAllSubNavGroups() {
    this.subNavGroupTargets.forEach((item) => {
      item.classList.remove("active");
    });
  }

  handleOverlayBackgroundClick(event) {
    event.preventDefault();
    this.authOverlayActive = false;
    this.basketOverlayActive = false;
    this.mobileMenuActive = false;
    this.activeSubNavId = null;
    this.update();
  }

  handleBasketQuantityUpdate(event) {
    this.basketQuantityTarget.innerText = event.detail;
  }

  handleScrollInterval() {
    // Safari can overscroll negatively at the top of the scroll; the journey back to zero makes
    // it seem like the user is scrolling downwards, but they are not, so make 0 the minimum value
    // Similarly it can extend beyond the real scrollable value at the bottom of the page. Again
    // the spring back to the correct position is not movement that is coming from the user, so cap
    // the scroll value at the other end to the maximum permissable value
    const maxScrollY = document.body.scrollHeight - window.innerHeight;
    const scrollY = Math.min(Math.max(window.scrollY, 0), maxScrollY);

    if (this.scrollY > scrollY) {
      if (this.direction != UP) {
        this.direction = UP;
        this.navTarget.classList.remove("hide");
      }
    } else if (this.scrollY === scrollY) {
      if (this.direction != STATIC) {
        this.direction = STATIC;
      }
    } else {
      if (this.direction != DOWN && scrollY > 200) {
        this.direction = DOWN;
        if (
          this.activeSubNavId === null &&
          !this.mobileMenuActive &&
          !this.basketOverlayActive &&
          !this.authOverlayActive
        ) {
          this.navTarget.classList.add("hide");
        }
      }
    }
    this.scrollY = scrollY;
  }

  handleNavigateTo(event) {
    const { url, pageTitle } = event.detail;
    window.history.pushState({ path: url }, "", url);
    const siteName = document.body.getAttribute("data-site-name");
    const title = `${pageTitle}${siteName ? ` - ${siteName}` : ""}`;
    document.title = title;
  }

  handleContinueShoppingClick(event) {
    if (this.hasBasketOverlayTarget) {
      event.preventDefault();
      this.basketOverlayActive = false;
      this.update();
    }
  }
}
