import Swiper from "swiper";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["swiper"];

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      speed: 400,
      slidesPerView: "auto",
      spaceBetween: 20,
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
  }

  handlePrevButtonClick() {
    this.swiper.slidePrev();
  }

  handleNextButtonClick() {
    this.swiper.slideNext();
  }
}
