import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleClick(event) {
    if (window.history.length > 1) {
      event.preventDefault();
      window.history.back();
    }
  }
}
