import { Controller } from "@hotwired/stimulus";
import { indexLoop } from "./utils";
import { triggerFocus } from "./utils";

export default class extends Controller {
  static targets = ["closeButton", "navLink", "item"];

  connect() {
    this.tabIndex = 0;
    this.interactiveEls = [
      this.closeButtonTarget,
      ...this.navLinkTargets,
      ...this.itemTargets,
    ];

    if (!document.body.classList.contains("inPreview")) {
      // Don't force focus when previewing in Wagtail
      triggerFocus(this.closeButtonTarget);
    }
  }

  handleKeyDown(event) {
    if (!this.element.classList.contains("active")) return;
    event.preventDefault();
    this.tabIndex = indexLoop(
      this.tabIndex,
      this.interactiveEls.length,
      event.shiftKey
    );

    triggerFocus(this.interactiveEls[this.tabIndex]);
  }
}
