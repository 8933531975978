import { Controller } from "@hotwired/stimulus";
import { getUrlAndTitleFromLink } from "./utils";

export default class extends Controller {
  static targets = ["grid", "loadMore", "noResults"];

  handlePageLinkClick(event) {
    if (this.element.hasAttribute("data-navigate-to")) {
      event.preventDefault();
      const { url, pageTitle } = getUrlAndTitleFromLink(event.currentTarget);
      window.dispatchEvent(
        new CustomEvent("ListViewLinkClick", { detail: { url, pageTitle } })
      );
    }
  }

  extractEls(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return {
      grid: doc.querySelector("[data-listView-target='grid']"),
      loadMore: doc.querySelector("[data-listView-target='loadMore']"),
      noResults: doc.querySelector("[data-listView-target='noResults']"),
    };
  }

  handleFilterChanged(event) {
    const { grid, loadMore, noResults } = this.extractEls(event.detail);
    if (grid && grid.children) {
      this.gridTarget.innerHTML = "";
      this.gridTarget.append(...grid.children);
    }
    this.loadMoreTarget.innerHTML = loadMore.innerHTML;
    this.noResultsTarget.innerHTML = noResults.innerHTML;
  }

  handleMoreLoaded(event) {
    const { grid, loadMore, noResults } = this.extractEls(event.detail);
    if (grid && grid.children) {
      this.gridTarget.append(...grid.children);
    }
    this.loadMoreTarget.innerHTML = loadMore.innerHTML;
    this.noResultsTarget.innerHTML = noResults.innerHTML;
  }
}
